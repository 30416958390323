import * as React from "react";
import logo from "./logo.svg";
import "./App.css";
import { DatePicker } from "antd";
import { Button, Checkbox, Form, Input } from "antd";
import { Layout, Space } from "antd";
import FacebookLogin from "react-facebook-login";

import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
} from "react-router-dom";

const { Header, Footer, Sider, Content } = Layout;

const headerStyle = {
  textAlign: "center",
  color: "#fff",
  height: 64,
  paddingInline: 50,
  lineHeight: "64px",
  backgroundColor: "#7dbcea",
};

const contentStyle = {
  textAlign: "center",
  minHeight: 120,
  lineHeight: "120px",
  color: "#fff",
  backgroundColor: "#108ee9",
};

const siderStyle = {
  textAlign: "center",
  lineHeight: "120px",
  color: "#fff",
  backgroundColor: "#3ba0e9",
};

const footerStyle = {
  textAlign: "center",
  color: "#fff",
  backgroundColor: "#7dbcea",
};

function Root() {
  return (
    <>
      <div id="sidebar">
        <h1>React Router Contacts</h1>
        <div>
          <form id="search-form" role="search">
            <input
              id="q"
              aria-label="Search contacts"
              placeholder="Search"
              type="search"
              name="q"
            />
            <div id="search-spinner" aria-hidden hidden={true} />
            <div className="sr-only" aria-live="polite"></div>
          </form>
          <form method="post">
            <button type="submit">New</button>
          </form>
        </div>
        <nav>
          <ul>
            <li>
              <a href={`/contacts/1`}>Your Name</a>
            </li>
            <li>
              <a href={`/contacts/2`}>Your Friend</a>
            </li>
          </ul>
        </nav>
      </div>
      <div id="detail"></div>
    </>
  );
}

function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}

const Login = () => {
  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout>
      <Content
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Form
          name="basic"
          //labelCol={{ span: 8 }}
          //wrapperCol={{ span: 16 }}
          //style={{ maxWidth: 600 }}
          //initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Correo electrónico"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Por favor ingresa tu correo electrónico!",
              },
            ]}
          >
            <Input placeholder="nombre@dominio.com" />
          </Form.Item>

          <Form.Item
            label="Contraseña"
            name="password"
            rules={[
              { required: true, message: "Por favor ingresa tu contraseña!" },
              {
                min: 8,
                message: "La contraseña debe tener al menos 8 caracteres",
              },
            ]}
          >
            <Input.Password placeholder="contraseña" />
          </Form.Item>

          {/*       <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                 <Checkbox>Remember me</Checkbox>
             </Form.Item>*/}

          <Form.Item
          //   wrapperCol={{ offset: 8, span: 16 }}
          >
            <Button type="primary" htmlType="submit">
              Iniciar sesión
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </Layout>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
  },
  {
    path: "login",
    element: <Login />,
  },
]);

function App() {
  const responseFacebook = (response) => {
    console.log(response);
  };
  const componentClicked = () => {
    console.log("clicked");
  };
  return <RouterProvider router={router} />;
  return (
    <div className="App">
      {/*        <FacebookLogin
            language="es_ES"
            scope="public_profile,email"
            appId="958255035318322"
            fields="id,first_name,last_name,middle_name,name,name_format,picture,short_name,email"
            onClick={componentClicked}
            callback={responseFacebook} />*/}
    </div>
  );
}

export default () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        flexDirection: "column",
      }}
    >
      <h1>Under construction</h1>
      <h4>Ticketeo, Inc.</h4>
      <span>Contact: contact@ticketeo.pe</span>
      <span>Phone: +1 (760) 284-8490</span>
    </div>
  );
};
